import { FilePdfFilled, LoadingOutlined } from "@ant-design/icons";
import { Card, message, Tooltip } from "antd";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import {
  GetReportRequest,
  GetReportRequestLayout,
  GetSurveyCampaignGroupReportResponse,
} from "../../constants/apiRequestResponse";
import TEST_IDS from "../../constants/cypressTestIds";
import { getReport } from "../../services/api";
import { NormalGroupQuestionReportKeys } from "../../services/i8tn/NormalGroupQuestionReport/keys";
import { getQueryParam } from "../../util";

interface Prop {
  surveyCampaignId?: string;
  report?: GetSurveyCampaignGroupReportResponse;
}

export default function NormalGroupView(props: Prop) {
  const [report, setReport] = useState("");
  const [isGetReportLoading, setIsGetReportLoading] = useState(false);
  const [isPrintAsPDFLoading, setIsPrintAsPDFLoading] = useState(false);
  const surveyCampaignId = getQueryParam("surveyCampaignId");

  useEffect(() => {
    const req: GetReportRequest = {
      surveyCampaignId: surveyCampaignId || "",
      format: "html",
      layout: GetReportRequestLayout.Group,
    };
    setIsGetReportLoading(true);
    getReport(req)
      .then((res) => {
        setReport(res?.data);
      })
      .catch((err) => {
        message.error(err.toString());
      })
      .finally(() => setIsGetReportLoading(false));
  }, [surveyCampaignId]);

  const onExportPDF = () => {
    setIsPrintAsPDFLoading(true);
    const req: GetReportRequest = {
      surveyCampaignId: surveyCampaignId || "",
      format: "pdf",
      layout: GetReportRequestLayout.Group,
    };
    getReport(req)
      .catch((err) => {
        message.error(err);
      })
      .finally(() => setIsPrintAsPDFLoading(false));
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-around",
      }}
    >
      <Card
        style={{
          width: "80%",
          maxWidth: 1000,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            gap: 20,
            marginBottom: 50,
          }}
        >
          <div>
            <p style={{ fontWeight: "bold" }}>
              {props.report?.surveyCampaign?.name}
            </p>
            <p style={{ color: "grey" }}>
              {props.report?.surveyTemplate?.name}
            </p>
          </div>
          {isPrintAsPDFLoading ? (
            <div>
              <LoadingOutlined style={{ fontSize: 30 }} />
            </div>
          ) : (
            <Tooltip
              title={t(
                NormalGroupQuestionReportKeys.normalGroupQuestionReport_downloadAsPDFTooltip
              )}
            >
              <FilePdfFilled
                data-testid={
                  TEST_IDS.REPORT_PAGE.group_download_as_pdf_button_id
                }
                onClick={(e) => {
                  onExportPDF();
                }}
                style={{ fontSize: 30 }}
              />
            </Tooltip>
          )}
        </div>

        {isGetReportLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <LoadingOutlined style={{ fontSize: 30 }} />
          </div>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: report }}></div>
        )}
      </Card>
    </div>
  );
}
