import {
  Button,
  Card,
  Input,
  List,
  PageHeader,
  Pagination,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SessionContext } from "../../App";
import SurveyCampaignStatusTag from "../../components/SurveyCampaignStatusTag";
import {
  GetSurveyCampaignByCompanyIdRequest,
  GetSurveyCampaignByCompanyIdResponse,
} from "../../constants/apiRequestResponse";
import {
  FeatureFlagStatus,
  FeatureFlagTemplateKey,
  SurveyTemplate,
} from "../../constants/types";
import {
  getSurveyCampaignsByCompanyId,
  getSurveyTemplates,
} from "../../services/api";
import { ReportsPageKeys } from "../../services/i8tn/ReportsPage/keys";
import { getQueryParam, parseTime, setAllQueryParams } from "../../util/index";

export default function ReportsPage() {
  const context = useContext(SessionContext);
  const { t } = useTranslation();
  const [
    getSurveyCampaignsByCompanyIdResponse,
    setGetSurveyCampaignsByCompanyIdResponse,
  ] = useState<GetSurveyCampaignByCompanyIdResponse | undefined>();
  const [req, setReq] = useState<GetSurveyCampaignByCompanyIdRequest>({
    companyId: context?.company?._id,
    limit: Number(getQueryParam("limit")) || 10,
    skip: Number(getQueryParam("skip")) || 0,
    search: getQueryParam("search") || "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [surveyTemplates, setSurveyTemplates] = useState<SurveyTemplate[]>([]);

  useEffect(() => {
    setIsLoading(true);
    getSurveyCampaignsByCompanyId(req)
      .then((res) => {
        if (!res.success) throw new Error(res.message);

        setGetSurveyCampaignsByCompanyIdResponse(res);
      })
      .catch((err) => {
        message.error(err.toString());
      })
      .finally(() => setIsLoading(false));

    getSurveyTemplates()
      .then((res) => {
        if (!res.success) throw new Error(res.message);
        setSurveyTemplates(res.surveyTemplates);
      })
      .catch((err) => {
        message.error(err.toString());
      });
  }, [context?.company?._id, req]);

  const dataSource = getSurveyCampaignsByCompanyIdResponse?.surveyCampaigns.map(
    (sc) => {
      const st = surveyTemplates.find((st) => st._id === sc.surveyTemplateId);
      return {
        ...sc,
        surveyTemplateName: st?.name,
        surveyTemplateType: st?.type,
      };
    }
  );

  const isDisableViewReportButton = context?.featureFlags?.find((ff) => {
    return (
      ff.key === FeatureFlagTemplateKey.IS_DISABLE_REPORT_READ &&
      ff.status === FeatureFlagStatus.ENABLED
    );
  })
    ? true
    : false;

  return (
    <div className="page-content">
      <PageHeader title={t(ReportsPageKeys.reportsPage_reportsTitleString)} />

      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Input.Search
            loading={isLoading}
            placeholder="Search for survey campaign"
            defaultValue={req.search}
            onSearch={(searchString) => {
              const newReq = {
                ...req,
                skip: 0,
                search: searchString,
              };

              setReq(newReq);
              setAllQueryParams(newReq);

              setIsLoading(true);
              getSurveyCampaignsByCompanyId(newReq)
                .then((res) => {
                  if (!res.success) throw new Error(res.message);

                  setGetSurveyCampaignsByCompanyIdResponse(res);
                })
                .catch((err) => {
                  message.error(err.toString());
                })
                .finally(() => setIsLoading(false));
            }}
            style={{ width: "50%" }}
          />

          {getSurveyCampaignsByCompanyIdResponse ? (
            <Pagination
              total={getSurveyCampaignsByCompanyIdResponse.total}
              showTotal={(total, range) => {
                return `${range[0]}-${range[1]} of ${total} items`;
              }}
              defaultCurrent={
                req?.skip && req?.limit ? req.skip / req.limit + 1 : 1
              }
              showSizeChanger={true}
              defaultPageSize={req.limit}
              onChange={(page, pageSize) => {
                const newReq = {
                  ...req,
                  limit: pageSize,
                  skip: (page - 1) * (pageSize ? pageSize : 0),
                };
                setAllQueryParams(newReq);
                setReq(newReq);

                setIsLoading(true);
                getSurveyCampaignsByCompanyId(newReq)
                  .then((res) => {
                    if (!res.success) throw new Error(res.message);

                    setGetSurveyCampaignsByCompanyIdResponse(res);
                  })
                  .catch((err) => {
                    message.error(err.toString());
                  })
                  .finally(() => setIsLoading(false));
              }}
            />
          ) : null}
        </div>
        <List
          dataSource={dataSource}
          renderItem={(row) => {
            return (
              <List.Item key={row._id}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        gap: 5,
                      }}
                    >
                      <a href={`/surveycampaign?surveyCampaignId=${row._id}`}>
                        {row.name || `(No name provided)`}
                      </a>
                      <SurveyCampaignStatusTag status={row.status} />
                    </div>

                    <div>
                      <p style={{ color: "grey" }}>{row.surveyTemplateName}</p>
                      <br />
                      <p
                        style={{
                          fontSize: 15,
                          fontStyle: "italic",
                          color: "grey",
                        }}
                      >
                        {t(ReportsPageKeys.reportsPage_lastUpdateString)}
                        {parseTime(row.createdAt)}
                      </p>
                    </div>
                  </div>

                  {isDisableViewReportButton ? (
                    <Button
                      onClick={() => {
                        message.warn(
                          t(
                            ReportsPageKeys.reportsPage_isDisableReportReadWarnString
                          )
                        );
                      }}
                    >
                      <>{t(ReportsPageKeys.reportsPage_viewReportBtn)}</>
                    </Button>
                  ) : (
                    <Button type="primary">
                      <a
                        href={`/report/view?surveyCampaignId=${row._id}&surveyTemplateType=${row.surveyTemplateType}`}
                      >
                        <>{t(ReportsPageKeys.reportsPage_viewReportBtn)}</>
                      </a>
                    </Button>
                  )}
                </div>
              </List.Item>
            );
          }}
        />
      </Card>
    </div>
  );
}
