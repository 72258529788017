import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Collapse,
  message,
  Modal,
  Skeleton,
  Table,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useSWR, { mutate } from "swr";
import { SessionContext } from "../../App";
import EditEmailTemplateForm from "../../components/EditEmailTemplate";
import {
  GetEmailTemplateByCompanyIdResponse,
  GetSurveyCampaignBySurveyCampaignIdResponse,
  GetSurveyResponseBySurveyCampaignIdResponse,
  UpdateEmailTemplateRequest,
} from "../../constants/apiRequestResponse";
import TEST_IDS from "../../constants/cypressTestIds";
import { COLORS } from "../../constants/misc";
import { EmailTemplate } from "../../constants/types";
import {
  fetcher,
  startSurveyCampaign,
  updateEmailTemplate,
} from "../../services/api";
import { EditEmailTemplatePageKeys } from "../../services/i8tn/EditEmailTemplatePage/keys";
import { SurveyCampaignFormKeys } from "../../services/i8tn/SurveyCampaignForm/keys";
import { getQueryParam, setQueryParam, stringParser } from "../../util";

type EmailInputParams = {
  currentUserName?: string;
  companyName?: string;
  surveyCampaignName?: string;
  link?: string;
  email?: string;
};
export default function Preview() {
  const context = useContext(SessionContext);

  const history = useHistory();

  const surveyCampaignId = getQueryParam("surveyCampaignId") || "";

  const { data: getSurveyResponseRes, isLoading: isSurveyResponseLoading } =
    useSWR<GetSurveyResponseBySurveyCampaignIdResponse>(
      `/surveyResponse/getBySurveyCampaignId/${surveyCampaignId}`,
      fetcher,
      {
        errorRetryCount: 0,
      }
    );

  const { data: getSurveyCampaignRes } =
    useSWR<GetSurveyCampaignBySurveyCampaignIdResponse>(
      `/surveyCampaign/getBySurveyCampaignId/${getQueryParam(
        "surveyCampaignId"
      )}`,
      fetcher,
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        errorRetryCount: 0,
      }
    );

  const { data: getEmailTemplateRes, isLoading: getEmailTemplateLoading } =
    useSWR<GetEmailTemplateByCompanyIdResponse>(
      `/emailTemplate/get/${context?.companyUser?.companyId}`,
      fetcher,
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        errorRetryCount: 0,
      }
    );

  const [emailTemplate, setEmailTemplate] = useState<
    EmailTemplate | undefined
  >();
  const [isUpdatingEmailTemplateLoading, setIsUpdatingEmailTemplateLoading] =
    useState(false);
  const [isSaveAsDraftLoading, setIsSaveAsDraftLoading] = useState(false);
  const [isSendLoading, setIsSendLoading] = useState(false);
  const [isEditEmailTemplate, setIsEditEmailTemplate] = useState(false);
  const [editorState, setEditorState] = useState<string | undefined>();

  const { t } = useTranslation();

  useEffect(() => {
    let _emailTemplate = getEmailTemplateRes?.emailTemplates?.find((et) => {
      return (
        et.surveyTemplateId ===
        getSurveyCampaignRes?.surveyCampaign?.surveyTemplateId
      );
    });

    if (!_emailTemplate) return;

    const se = getSurveyResponseRes?.surveyResponses[0]?.surveyEmployee;
    if (!se) return;

    const inputParams: EmailInputParams = {
      currentUserName: `${se?.fname} ${se?.lname}`,
      companyName: `${context?.company?.name}`,
      surveyCampaignName: getSurveyCampaignRes?.surveyCampaign?.name,
      email: context?.company?.helpEmail,
    };
    _emailTemplate.content = stringParser(
      _emailTemplate?.content || "",
      inputParams
    );

    setEmailTemplate(_emailTemplate);
  }, [
    getEmailTemplateRes,
    getSurveyCampaignRes,
    getSurveyResponseRes,
    context?.company,
  ]);

  return (
    <div>
      <Typography.Title
        data-testid={TEST_IDS.SURVEY_CAMPAIGN_FORM.preview_and_send_label_id}
        level={3}
      >
        {t(SurveyCampaignFormKeys.surveyCampaignForm_previewAndSendTitleString)}
      </Typography.Title>
      <Table
        loading={isSurveyResponseLoading}
        rowKey="_id"
        size="small"
        columns={[
          {
            title: (
              <>
                {t(
                  SurveyCampaignFormKeys.surveyCampaignForm_previewFirstNameTableHeader
                )}
              </>
            ),
            dataIndex: "fname",
          },
          {
            title: (
              <>
                {t(
                  SurveyCampaignFormKeys.surveyCampaignForm_previewLastNameTableHeader
                )}
              </>
            ),
            dataIndex: "lname",
          },
          {
            title: (
              <>
                {t(
                  SurveyCampaignFormKeys.surveyCampaignForm_previewEmailTableHeader
                )}
              </>
            ),
            dataIndex: "email",
          },
          {
            title: (
              <>
                {t(
                  SurveyCampaignFormKeys.surveyCampaignForm_previewPositionTableHeader
                )}
              </>
            ),
            dataIndex: "position",
          },
          {
            title: (
              <>
                {t(
                  SurveyCampaignFormKeys.surveyCampaignForm_previewTeamTableHeader
                )}
              </>
            ),
            dataIndex: "team",
          },
          {
            title: (
              <>
                {t(
                  SurveyCampaignFormKeys.surveyCampaignForm_previewGenderTableHeader
                )}
              </>
            ),
            dataIndex: "gender",
          },
        ]}
        dataSource={getSurveyResponseRes?.surveyResponses.map((sr) => {
          return sr.surveyEmployee;
        })}
        pagination={false}
        scroll={{
          x: true,
        }}
      />

      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: 10,
          paddingBottom: 10,
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 10,
            flexDirection: "row",
          }}
        >
          <h4>
            {t(
              SurveyCampaignFormKeys.surveyCampaignForm_previewExampleEmailString
            )}
          </h4>

          {emailTemplate ? (
            <EditOutlined
              style={{
                color: COLORS.PRIMARY_COLOR,
                fontSize: 18,
              }}
              data-testid={TEST_IDS.SURVEY_CAMPAIGN_FORM.edit_email_template_id}
              onClick={() => {
                setQueryParam("emailTemplateId", emailTemplate?._id);
                setIsEditEmailTemplate(true);
              }}
            />
          ) : null}
        </div>
        <Card>
          {getEmailTemplateLoading ? (
            <Skeleton />
          ) : isEditEmailTemplate && emailTemplate ? (
            <div style={{ display: "flex", gap: 10, flexDirection: "column" }}>
              <EditEmailTemplateForm
                isShowFooter={false}
                onUpdate={(_editorState) => setEditorState(_editorState)}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 10,
                }}
              >
                <Button
                  type="link"
                  onClick={() => setIsEditEmailTemplate(false)}
                >
                  <>
                    {t(EditEmailTemplatePageKeys.editEmailTemplate_cancelBtn)}
                  </>
                </Button>
                <Button
                  loading={isUpdatingEmailTemplateLoading}
                  type="primary"
                  onClick={() => {
                    setIsUpdatingEmailTemplateLoading(true);

                    if (editorState) {
                      emailTemplate.content = editorState;
                    }

                    const req: UpdateEmailTemplateRequest = {
                      emailTemplate: emailTemplate,
                    };

                    updateEmailTemplate(req)
                      .then((res) => {
                        if (!res.success) throw new Error(res.message);

                        message.success("Updated");
                        setIsEditEmailTemplate(false);

                        mutate(
                          `/emailTemplate/get/${context?.companyUser?.companyId}`
                        );
                      })
                      .catch((err) => message.error(err))
                      .finally(() => setIsUpdatingEmailTemplateLoading(false));
                  }}
                  data-testid={
                    TEST_IDS.SURVEY_CAMPAIGN_FORM.save_email_template_id
                  }
                >
                  <>{t(EditEmailTemplatePageKeys.editEmailTemplate_saveBtn)}</>
                </Button>
              </div>
            </div>
          ) : (
            <div
              style={{
                maxHeight: 600,
                overflow: "scroll",
              }}
              dangerouslySetInnerHTML={{
                __html: emailTemplate?.content || "",
              }}
            ></div>
          )}
        </Card>
      </div>

      <Typography.Title level={3}>
        {t(SurveyCampaignFormKeys.surveyCampaignForm_faqString)}
      </Typography.Title>
      <Collapse>
        <Collapse.Panel
          header={t(SurveyCampaignFormKeys.surveyCampaignForm_whatIsLinkString)}
          key="1"
        >
          <p>{t(SurveyCampaignFormKeys.surveyCampaignForm_uniqueLinkString)}</p>
        </Collapse.Panel>
      </Collapse>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 10,
        }}
      >
        <Button onClick={(e) => history.goBack()} type="link">
          {t(SurveyCampaignFormKeys.surveyCampaignForm_previewBackBtn)}
        </Button>
        <div style={{ display: "flex", gap: 10 }}>
          <Button
            loading={isSaveAsDraftLoading}
            onClick={(e) => {
              setIsSaveAsDraftLoading(true);
              setTimeout(() => {
                message.success(`Survey campaign saved!`);
              }, 1 * 1000);
              setTimeout(() => {
                history.push(
                  `/surveycampaign?surveyCampaignId=${getSurveyCampaignRes?.surveyCampaign._id}`
                );
              }, 1 * 1000);
            }}
          >
            {t(SurveyCampaignFormKeys.surveyCampaignForm_previewDraftBtn)}
          </Button>
          <Button
            loading={isSendLoading}
            onClick={(e) => {
              Modal.success({
                title: t(
                  SurveyCampaignFormKeys.surveyCampaignForm_previewSendAreYouSurePrompt
                ),
                onOk: () => {
                  setIsSendLoading(true);

                  startSurveyCampaign(
                    getSurveyCampaignRes?.surveyCampaign._id || ""
                  )
                    .then(() => {
                      message.success(
                        t(
                          SurveyCampaignFormKeys.surveyCampaignForm_surveyCampaignStartedPrompt
                        )
                      );

                      setTimeout(() => {
                        history.push(
                          `/surveycampaign?surveyCampaignId=${getSurveyCampaignRes?.surveyCampaign._id}`
                        );
                      }, 1 * 1000);
                    })
                    .catch((err) => {
                      setIsSendLoading(false);
                      message.error(err.message);
                    });
                },
              });
            }}
            type="primary"
            disabled={isEditEmailTemplate}
          >
            {t(SurveyCampaignFormKeys.surveyCampaignForm_previewSendBtn)}
          </Button>
        </div>
      </div>
    </div>
  );
}
