import moment from "moment";
import { Company } from "../constants/types";

export const parseTime = (time?: number) => {
  if (!time) {
    return time;
  }
  return moment(time).format("DD/MM/YYYY hh:mma");
};

export const parseTimeAgo = (time?: number) => {
  if (!time) {
    return time;
  }
  return moment(time).fromNow();
};

export const parseDate = (time) => {
  return moment(time).format("DD/MM/YYYY");
};

export const getIsMobile = () => {
  return window.innerWidth < 400;
};

export const getIsCompanyEmail = (company: Company, email: string): boolean => {
  for (let emailDomain of company?.emailDomains || []) {
    const regex = new RegExp(`.+@${emailDomain}$`);
    if (email.match(regex)) {
      return true;
    }
  }
  return false;
};
/**
 * Takes in a string, with variables formatted like [$VAR_NAME],
 * and parses it to replace variables with actual values from params.
 * E.g.
 *
 * [$CUSTOMER_NAME] should take value of params.CUSTOMER_NAME
 * [$QUANTITY] should take value of params.QUANTITY
 */
export const stringParser = (inputString: string, inputParams: {}) => {
  /**
   * look for [$var] throughout the template
   */
  const emailVariables = [
    ...new Set(inputString.match(/(\[\$\w*\])/g)),
  ] as string[];

  let newString = inputString;
  emailVariables.forEach((variable) => {
    /**
     * Check if emailInputParams has any keys that matches variable
     * If yes, replace
     */
    const key = Object.keys(inputParams).find((key) => variable.includes(key));
    if (key) {
      newString = newString.replaceAll(variable, inputParams[key]);
    }
  });

  return newString;
};

/**
 * Override and set all query params
 * @param {Object} queryParams
 */
export const setAllQueryParams = (queryParams) => {
  const searchParams = new URLSearchParams(window.location.search);

  Object.keys(queryParams).map((key) => {
    searchParams.set(key, queryParams[key]);
    return null;
  });

  const url = `${window.location.origin}${
    window.location.pathname
  }?${searchParams.toString()}`;
  window.history.pushState({ path: url }, "", url);
};

export const deleteAllQueryParams = () => {
  const searchParams = new URLSearchParams(window.location.search);

  searchParams.forEach((value, key) => {
    searchParams.delete(key);
  });

  const url = `${window.location.origin}${
    window.location.pathname
  }?${searchParams.toString()}`;
  window.history.pushState({ path: url }, "", url);
};

export function getAllQueryParams(): { [key: string]: string } {
  const searchParams = new URLSearchParams(window.location.search);

  const object = {};
  for (let pair of searchParams.entries()) {
    object[pair[0]] = pair[1];
  }

  return object;
}

/**
 * Override and set 1 query param
 * @param {string} key
 * @param {any} value
 */
export const setQueryParam = (key, value) => {
  const searchParams = new URLSearchParams(window.location.search);

  searchParams.set(key, value);

  const url = `${window.location.origin}${
    window.location.pathname
  }?${searchParams.toString()}`;
  window.history.pushState({ path: url }, "", url);
};

/**
 * Get 1 query param
 * @param {string} key
 */
export function getQueryParam(key: string): string | undefined {
  const searchParams = new URLSearchParams(window.location.search);

  return searchParams.get(key) || undefined;
}
