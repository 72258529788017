import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Affix,
  Button,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Select,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import shortId from "shortid";
import useSWR, { mutate } from "swr";
import { read } from "xlsx";
import PositionsTag from "../../components/PositionsTag";
import config from "../../config";
import {
  GetSurveyResponseBySurveyCampaignIdResponse,
  SaveAsDraftUpdateSurveyCampaignEmployeesRequest,
  UpdateSurveyCampaignEmployeesRequest,
  ValidateUpdateSurveyCampaignEmployeesErrorResponse,
  ValidateUpdateSurveyCampaignEmployeesRequest,
} from "../../constants/apiRequestResponse";
import { Gender, Positions, SurveyEmployee } from "../../constants/types";
import {
  fetcher,
  saveAsDraftUpdateSurveyCampaignEmployees,
  updateSurveyCampaignEmployees,
  validateUpdateSurveyCampaignEmployees,
} from "../../services/api";
import { SurveyCampaign360FormKeys } from "../../services/i8tn/SurveyCampaign360Form/keys";
import { SurveyCampaignFormKeys } from "../../services/i8tn/SurveyCampaignForm/keys";
import { getQueryParam } from "../../util";

export default function Upload360Employees() {
  // similar to SurveyEmployee but with errors:string[]
  type Box = {
    _id: string;
    fname: string;
    lname: string;
    email: string;
    gender: Gender;
    position: string;
    team: string;
    participantId?: string;
    errorByField: {
      [key: string]: string;
    };
    createdAt: number;
  };
  const { t } = useTranslation();
  const surveyCampaignId = getQueryParam("surveyCampaignId") || "";
  const history = useHistory();

  const { data: getSurveyResponseRes } =
    useSWR<GetSurveyResponseBySurveyCampaignIdResponse>(
      `/surveyResponse/getBySurveyCampaignId/${surveyCampaignId}`,
      fetcher,
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        errorRetryCount: 0,
      }
    );

  const [form] = Form.useForm();
  const [drawerMode, setDrawerMode] = useState<"hidden" | "edit" | "add">(
    "hidden"
  );
  const [isSaveSurveyEmployeeLoading, setIsSaveSurveyEmployeeLoading] =
    useState(false);
  const [isSaveAsDraftLoading, setIsSaveAsDraftLoading] = useState(false);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [surveyEmployeeById, setSurveyEmployeeById] = useState<{
    [key: string]: Box;
  }>({});

  useEffect(() => {
    if (!getSurveyResponseRes?.surveyResponses) return;
    getSurveyResponseRes.surveyResponses.forEach((sr) => {
      if (!sr.surveyEmployee._id) return;
      surveyEmployeeById[sr.surveyEmployee._id] = {
        ...sr.surveyEmployee,
        errorByField: {},
      };
    });

    const req: ValidateUpdateSurveyCampaignEmployeesRequest = {
      surveyEmployees: Object.values(surveyEmployeeById),
    };
    validateUpdateSurveyCampaignEmployees(req, surveyCampaignId)
      .then(() => {
        setSurveyEmployeeById(surveyEmployeeById);
      })
      .catch((err: ValidateUpdateSurveyCampaignEmployeesErrorResponse) => {
        if (!err.details) {
          message.error(err.message);
          return;
        }

        Object.keys(err.details).forEach((id) => {
          if (!surveyEmployeeById[id]) return;
          surveyEmployeeById[id].errorByField = err.details[id];
        });
        setSurveyEmployeeById(surveyEmployeeById);
      });
  }, [getSurveyResponseRes, surveyCampaignId, surveyEmployeeById]);

  function PreviewSurveyEmployeeBox(props: { surveyEmployee: SurveyEmployee }) {
    let errorByField =
      surveyEmployeeById[props.surveyEmployee._id]?.errorByField;

    const numberOfErrors = Object.values(errorByField).length;

    return (
      <div
        style={{
          minHeight: 120,
          width: 200,
          cursor: "pointer",
          border: "1px solid lightgrey",
          padding: 10,
        }}
        onClick={() => {
          setDrawerMode("edit");

          const fields = [
            "_id",
            "fname",
            "lname",
            "email",
            "gender",
            "team",
            "position",
            "participantId",
            "createdAt",
          ].map((name) => {
            return {
              name: name,
              value: props.surveyEmployee[name],
            };
          });
          form.setFields(fields);

          // need set a slight delay else it wont render, idk why
          // but it works out as well UX wise as it draws user attention to it
          setTimeout(() => {
            Object.keys(errorByField).forEach((name) => {
              const errors = errorByField[name];
              form.setFields([
                {
                  name: name,
                  errors: [errors],
                },
              ]);
            });
          }, 0.2 * 1000);
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <PositionsTag position={props.surveyEmployee.position} />

          <Tooltip
            title={t(
              SurveyCampaign360FormKeys.surveyCampaign360Form_employeesClearGroupTooltip
            )}
          >
            <CloseOutlined
              onClick={() => {
                Modal.confirm({
                  title: (
                    <>
                      {t(
                        SurveyCampaign360FormKeys.surveyCampaign360Form_employeesConfirmClearGroupPrompt
                      )}
                    </>
                  ),
                  onOk: () => {
                    const newSurveyEmployeeById = Object.assign(
                      {},
                      surveyEmployeeById
                    );

                    delete newSurveyEmployeeById[props.surveyEmployee._id];

                    setSurveyEmployeeById(newSurveyEmployeeById);
                  },
                });
              }}
            />
          </Tooltip>
        </div>

        <Tooltip
          title={t(
            SurveyCampaign360FormKeys.surveyCampaign360Form_employeesClickToEditTooltip
          )}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minWidth: 80,
              alignItems: "flex-start",
            }}
          >
            <p
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {props.surveyEmployee.fname} {props.surveyEmployee.lname}
            </p>
            <p>{props.surveyEmployee.email}</p>
            <p>{props.surveyEmployee.team}</p>
            {numberOfErrors > 0 ? (
              <p style={{ color: "red" }}>
                <ExclamationCircleOutlined /> {numberOfErrors} errors found.
              </p>
            ) : null}
          </div>
        </Tooltip>
      </div>
    );
  }

  function PlaceholderNonParticipantBox(props: {
    participant: SurveyEmployee;
    position: Positions;
  }) {
    return (
      <Button
        style={{
          height: 120,
          width: 200,
          border: "dashed 2px lightgrey",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "wrap",
        }}
        onClick={() => {
          form.setFields([
            {
              name: "_id",
              value: shortId(),
            },
            {
              name: "participantId",
              value: props.participant._id,
            },
            {
              name: "position",
              value: props.position,
            },
            {
              name: "createdAt",
              value: Date.now(),
            },
          ]);

          setDrawerMode("add");
        }}
      >
        Add a {props.position.toLowerCase()}
      </Button>
    );
  }

  const hasErrors =
    Object.values(surveyEmployeeById).filter(
      (se) => Object.values(se.errorByField).length > 0
    ).length > 0;

  return (
    <div style={{ display: "flex", gap: 50, flexDirection: "column" }}>
      <div>
        <Typography.Title level={3}>
          {t(
            SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesTitleString
          )}
        </Typography.Title>
        <p>
          {t(
            SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesDownloadString
          )}{" "}
          <a download href={config.assets.employees_360_template_2_xlsx_url}>
            {t(
              SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesSampleExcelLink
            )}
          </a>
          {t(
            SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesExampleString
          )}
        </p>
        <input
          type="file"
          onChange={(e) => {
            /**
             * It's possible for user to manually create employees, but then decide to upload.
             * The correct behavior would be to override it.
             * However, warn user before proceeding first.
             */
            if (Object.values(surveyEmployeeById).length > 0) {
              Modal.confirm({
                title: (
                  <>
                    {t(
                      SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesOverridePrompt
                    )}
                  </>
                ),
                onOk: () => {
                  parseFile(e);
                },
              });
            } else {
              parseFile(e);
            }

            function parseFile(e: React.ChangeEvent<HTMLInputElement>) {
              try {
                const file = e.target.files && e.target.files[0];

                if (file) {
                  const reader = new FileReader();
                  const rABS = !!reader?.readAsBinaryString;
                  reader.onload = (e) => {
                    const bstr = e?.target?.result;
                    const wb = read(bstr, {
                      type: rABS ? "binary" : "array",
                    });

                    const sheet = Object.values(wb.Sheets)[0];

                    const STARTING_ROW = 3;
                    const ENDING_ROW = Math.max(
                      ...Object.keys(sheet)
                        .map((key) => Number(key.replace(/^\D+/g, "")))
                        .filter((n) => n)
                    );

                    const valueByCell: {
                      [key: string]: string;
                    } = {};
                    Object.keys(sheet).forEach((cell) => {
                      valueByCell[cell] = sheet[cell].v;
                    });

                    const boxById: {
                      [key: string]: Box;
                    } = {};
                    for (let i = STARTING_ROW; i <= ENDING_ROW; i++) {
                      const participantId = shortId();
                      const participant: Box = {
                        _id: participantId,
                        fname: valueByCell[`A${i}`],
                        lname: valueByCell[`B${i}`],
                        email: valueByCell[`C${i}`],
                        gender: valueByCell[`D${i}`] as Gender,
                        position: Positions.Participant,
                        team: valueByCell[`E${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (participant.fname) {
                        boxById[participant._id] = participant;
                      }

                      const lineManager1: Box = {
                        _id: shortId(),
                        fname: valueByCell[`F${i}`],
                        lname: valueByCell[`G${i}`],
                        email: valueByCell[`H${i}`],
                        gender: valueByCell[`I${i}`] as Gender,
                        position: Positions.LineManager,
                        team: valueByCell[`J${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (lineManager1.fname) {
                        boxById[lineManager1._id] = lineManager1;
                      }

                      const lineManager2: Box = {
                        _id: shortId(),
                        fname: valueByCell[`K${i}`],
                        lname: valueByCell[`L${i}`],
                        email: valueByCell[`M${i}`],
                        gender: valueByCell[`N${i}`] as Gender,
                        position: Positions.LineManager,
                        team: valueByCell[`O${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (lineManager2.fname) {
                        boxById[lineManager2._id] = lineManager2;
                      }

                      const lineManager3: Box = {
                        _id: shortId(),
                        fname: valueByCell[`P${i}`],
                        lname: valueByCell[`Q${i}`],
                        email: valueByCell[`R${i}`],
                        gender: valueByCell[`S${i}`] as Gender,
                        position: Positions.LineManager,
                        team: valueByCell[`T${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (lineManager3.fname) {
                        boxById[lineManager3._id] = lineManager3;
                      }

                      const team1: Box = {
                        _id: shortId(),
                        fname: valueByCell[`U${i}`],
                        lname: valueByCell[`V${i}`],
                        email: valueByCell[`W${i}`],
                        gender: valueByCell[`X${i}`] as Gender,
                        position: Positions.Team,
                        team: valueByCell[`Y${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (team1.fname) {
                        boxById[team1._id] = team1;
                      }

                      const team2: Box = {
                        _id: shortId(),
                        fname: valueByCell[`Z${i}`],
                        lname: valueByCell[`AA${i}`],
                        email: valueByCell[`AB${i}`],
                        gender: valueByCell[`AC${i}`] as Gender,
                        position: Positions.Team,
                        team: valueByCell[`AD${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (team2.fname) {
                        boxById[team2._id] = team2;
                      }

                      const team3: Box = {
                        _id: shortId(),
                        fname: valueByCell[`AE${i}`],
                        lname: valueByCell[`AF${i}`],
                        email: valueByCell[`AG${i}`],
                        gender: valueByCell[`AH${i}`] as Gender,
                        position: Positions.Team,
                        team: valueByCell[`AI${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (team3.fname) {
                        boxById[team3._id] = team3;
                      }

                      const team4: Box = {
                        _id: shortId(),
                        fname: valueByCell[`AJ${i}`],
                        lname: valueByCell[`AK${i}`],
                        email: valueByCell[`AL${i}`],
                        gender: valueByCell[`AM${i}`] as Gender,
                        position: Positions.Team,
                        team: valueByCell[`AN${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (team4.fname) {
                        boxById[team4._id] = team4;
                      }

                      const team5: Box = {
                        _id: shortId(),
                        fname: valueByCell[`AO${i}`],
                        lname: valueByCell[`AP${i}`],
                        email: valueByCell[`AQ${i}`],
                        gender: valueByCell[`AR${i}`] as Gender,
                        position: Positions.Team,
                        team: valueByCell[`AS${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (team5.fname) {
                        boxById[team5._id] = team5;
                      }

                      const team6: Box = {
                        _id: shortId(),
                        fname: valueByCell[`AT${i}`],
                        lname: valueByCell[`AU${i}`],
                        email: valueByCell[`AV${i}`],
                        gender: valueByCell[`AW${i}`] as Gender,
                        position: Positions.Team,
                        team: valueByCell[`AX${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (team6.fname) {
                        boxById[team6._id] = team6;
                      }

                      const team7: Box = {
                        _id: shortId(),
                        fname: valueByCell[`AY${i}`],
                        lname: valueByCell[`AZ${i}`],
                        email: valueByCell[`BA${i}`],
                        gender: valueByCell[`BB${i}`] as Gender,
                        position: Positions.Team,
                        team: valueByCell[`BC${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (team7.fname) {
                        boxById[team7._id] = team7;
                      }

                      const team8: Box = {
                        _id: shortId(),
                        fname: valueByCell[`BD${i}`],
                        lname: valueByCell[`BE${i}`],
                        email: valueByCell[`BF${i}`],
                        gender: valueByCell[`BG${i}`] as Gender,
                        position: Positions.Team,
                        team: valueByCell[`BH${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (team8.fname) {
                        boxById[team8._id] = team8;
                      }

                      const team9: Box = {
                        _id: shortId(),
                        fname: valueByCell[`BI${i}`],
                        lname: valueByCell[`BJ${i}`],
                        email: valueByCell[`BK${i}`],
                        gender: valueByCell[`BL${i}`] as Gender,
                        position: Positions.Team,
                        team: valueByCell[`BM${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (team9.fname) {
                        boxById[team9._id] = team9;
                      }

                      const team10: Box = {
                        _id: shortId(),
                        fname: valueByCell[`BN${i}`],
                        lname: valueByCell[`BO${i}`],
                        email: valueByCell[`BP${i}`],
                        gender: valueByCell[`BQ${i}`] as Gender,
                        position: Positions.Team,
                        team: valueByCell[`BR${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (team10.fname) {
                        boxById[team10._id] = team10;
                      }

                      const team11: Box = {
                        _id: shortId(),
                        fname: valueByCell[`BS${i}`],
                        lname: valueByCell[`BT${i}`],
                        email: valueByCell[`BU${i}`],
                        gender: valueByCell[`BV${i}`] as Gender,
                        position: Positions.Team,
                        team: valueByCell[`BW${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (team11.fname) {
                        boxById[team11._id] = team11;
                      }

                      const team12: Box = {
                        _id: shortId(),
                        fname: valueByCell[`BX${i}`],
                        lname: valueByCell[`BY${i}`],
                        email: valueByCell[`BZ${i}`],
                        gender: valueByCell[`CA${i}`] as Gender,
                        position: Positions.Team,
                        team: valueByCell[`CB${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (team12.fname) {
                        boxById[team12._id] = team12;
                      }
                      const report1: Box = {
                        _id: shortId(),
                        fname: valueByCell[`CC${i}`],
                        lname: valueByCell[`CD${i}`],
                        email: valueByCell[`CE${i}`],
                        gender: valueByCell[`CF${i}`] as Gender,
                        position: Positions.Reports,
                        team: valueByCell[`CG${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (report1.fname) {
                        boxById[report1._id] = report1;
                      }

                      const report2: Box = {
                        _id: shortId(),
                        fname: valueByCell[`CH${i}`],
                        lname: valueByCell[`CI${i}`],
                        email: valueByCell[`CJ${i}`],
                        gender: valueByCell[`CK${i}`] as Gender,
                        position: Positions.Reports,
                        team: valueByCell[`CL${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (report2.fname) {
                        boxById[report2._id] = report2;
                      }

                      const report3: Box = {
                        _id: shortId(),
                        fname: valueByCell[`CM${i}`],
                        lname: valueByCell[`CN${i}`],
                        email: valueByCell[`CO${i}`],
                        gender: valueByCell[`CP${i}`] as Gender,
                        position: Positions.Reports,
                        team: valueByCell[`CQ${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (report3.fname) {
                        boxById[report3._id] = report3;
                      }

                      const report4: Box = {
                        _id: shortId(),
                        fname: valueByCell[`CR${i}`],
                        lname: valueByCell[`CS${i}`],
                        email: valueByCell[`CT${i}`],
                        gender: valueByCell[`CU${i}`] as Gender,
                        position: Positions.Reports,
                        team: valueByCell[`CV${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (report4.fname) {
                        boxById[report4._id] = report4;
                      }

                      const report5: Box = {
                        _id: shortId(),
                        fname: valueByCell[`CW${i}`],
                        lname: valueByCell[`CX${i}`],
                        email: valueByCell[`CY${i}`],
                        gender: valueByCell[`CZ${i}`] as Gender,
                        position: Positions.Reports,
                        team: valueByCell[`DA${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (report5.fname) {
                        boxById[report5._id] = report5;
                      }

                      const report6: Box = {
                        _id: shortId(),
                        fname: valueByCell[`DB${i}`],
                        lname: valueByCell[`DC${i}`],
                        email: valueByCell[`DD${i}`],
                        gender: valueByCell[`DE${i}`] as Gender,
                        position: Positions.Reports,
                        team: valueByCell[`DF${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (report6.fname) {
                        boxById[report6._id] = report6;
                      }

                      const report7: Box = {
                        _id: shortId(),
                        fname: valueByCell[`DG${i}`],
                        lname: valueByCell[`DH${i}`],
                        email: valueByCell[`DI${i}`],
                        gender: valueByCell[`DJ${i}`] as Gender,
                        position: Positions.Reports,
                        team: valueByCell[`DK${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (report7.fname) {
                        boxById[report7._id] = report7;
                      }

                      const report8: Box = {
                        _id: shortId(),
                        fname: valueByCell[`DL${i}`],
                        lname: valueByCell[`DM${i}`],
                        email: valueByCell[`DN${i}`],
                        gender: valueByCell[`DO${i}`] as Gender,
                        position: Positions.Reports,
                        team: valueByCell[`DP${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (report8.fname) {
                        boxById[report8._id] = report8;
                      }

                      const report9: Box = {
                        _id: shortId(),
                        fname: valueByCell[`DQ${i}`],
                        lname: valueByCell[`DR${i}`],
                        email: valueByCell[`DS${i}`],
                        gender: valueByCell[`DT${i}`] as Gender,
                        position: Positions.Reports,
                        team: valueByCell[`DU${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (report9.fname) {
                        boxById[report9._id] = report9;
                      }

                      const report10: Box = {
                        _id: shortId(),
                        fname: valueByCell[`DV${i}`],
                        lname: valueByCell[`DW${i}`],
                        email: valueByCell[`DX${i}`],
                        gender: valueByCell[`DY${i}`] as Gender,
                        position: Positions.Reports,
                        team: valueByCell[`DZ${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (report10.fname) {
                        boxById[report10._id] = report10;
                      }

                      const report11: Box = {
                        _id: shortId(),
                        fname: valueByCell[`EA${i}`],
                        lname: valueByCell[`EB${i}`],
                        email: valueByCell[`EC${i}`],
                        gender: valueByCell[`ED${i}`] as Gender,
                        position: Positions.Reports,
                        team: valueByCell[`EE${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (report11.fname) {
                        boxById[report11._id] = report11;
                      }

                      const report12: Box = {
                        _id: shortId(),
                        fname: valueByCell[`EF${i}`],
                        lname: valueByCell[`EG${i}`],
                        email: valueByCell[`EH${i}`],
                        gender: valueByCell[`EI${i}`] as Gender,
                        position: Positions.Reports,
                        team: valueByCell[`EJ${i}`],
                        participantId: participantId,
                        errorByField: {},
                        createdAt: Date.now(),
                      };
                      if (report12.fname) {
                        boxById[report12._id] = report12;
                      }
                    }

                    const req: ValidateUpdateSurveyCampaignEmployeesRequest = {
                      surveyEmployees: Object.values(boxById),
                    };
                    validateUpdateSurveyCampaignEmployees(req, surveyCampaignId)
                      .then(() => {
                        setSurveyEmployeeById(boxById);
                      })
                      .catch(
                        (
                          err: ValidateUpdateSurveyCampaignEmployeesErrorResponse
                        ) => {
                          if (!err.details) {
                            message.error(err.message);
                            return;
                          }

                          Object.keys(err.details).forEach((id) => {
                            if (!boxById[id]) return;
                            boxById[id].errorByField = err.details[id];
                          });
                          setSurveyEmployeeById(boxById);
                        }
                      );
                  };

                  if (rABS) reader.readAsBinaryString(file);
                  else reader.readAsArrayBuffer(file);
                }
              } catch (err) {
                message.error(
                  typeof err === "string"
                    ? err
                    : typeof err === "object"
                    ? err?.toString()
                    : `Unable to upload template`
                );
              }
            }
          }}
        />
        <p style={{ color: "grey" }}>
          <>
            {t(
              SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesSupportedFormatsString
            )}
          </>
        </p>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 20,
          width: "100%",
          flexWrap: "wrap",
          overflow: "scroll",
        }}
      >
        <Button
          style={{
            minHeight: 450,
            width: 200,
            border: "dashed 2px lightgrey",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            const _id = shortId();
            form.setFields([
              {
                name: "_id",
                value: _id,
              },
              {
                name: "participantId",
                value: _id,
              },
              {
                name: "position",
                value: Positions.Participant,
              },
            ]);
            setDrawerMode("add");
          }}
        >
          {t(
            SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddNewParticipantString
          )}
        </Button>
        {Object.values(surveyEmployeeById)
          .filter((se) => se.position === Positions.Participant)
          .sort((a, b) => b.createdAt - a.createdAt)
          .map((participant) => {
            const lineManagers: SurveyEmployee[] = [];
            const teams: SurveyEmployee[] = [];
            const reports: SurveyEmployee[] = [];

            Object.values(surveyEmployeeById).forEach((d) => {
              if (d.participantId !== participant._id) return;
              if (d.position === Positions.LineManager) {
                lineManagers.push(d);
              } else if (d.position === Positions.Team) {
                teams.push(d);
              } else if (d.position === Positions.Reports) {
                reports.push(d);
              }
            });

            teams.splice(2, 0, participant);

            return (
              <div
                key={participant.participantId}
                style={{
                  border: "solid 1px lightgrey",
                  minHeight: 300,
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: 5,
                  }}
                >
                  <Tooltip
                    title={t(
                      SurveyCampaign360FormKeys.surveyCampaign360Form_employeesClearGroupTooltip
                    )}
                  >
                    <CloseOutlined
                      onClick={() => {
                        Modal.confirm({
                          title: (
                            <>
                              {t(
                                SurveyCampaign360FormKeys.surveyCampaign360Form_employeesConfirmClearGroupPrompt
                              )}
                            </>
                          ),
                          onOk: () => {
                            const newSurveyEmployeeById = Object.assign(
                              {},
                              surveyEmployeeById
                            );

                            Object.values(newSurveyEmployeeById).forEach(
                              (se) => {
                                if (
                                  se.participantId === participant.participantId
                                ) {
                                  delete newSurveyEmployeeById[se._id];
                                }
                              }
                            );

                            setSurveyEmployeeById(newSurveyEmployeeById);
                          },
                        });
                      }}
                    />
                  </Tooltip>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                    padding: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* Line manager */}
                  <div
                    style={{
                      display: "flex",
                      gap: 5,
                    }}
                  >
                    {lineManagers.map((lineManager) => (
                      <PreviewSurveyEmployeeBox
                        key={lineManager._id}
                        surveyEmployee={lineManager}
                      />
                    ))}
                    <PlaceholderNonParticipantBox
                      participant={participant}
                      position={Positions.LineManager}
                    />
                  </div>

                  {/* Team and participant*/}
                  <div
                    style={{
                      display: "flex",
                      gap: 5,
                    }}
                  >
                    {teams.map((tnp) => (
                      <PreviewSurveyEmployeeBox
                        key={tnp._id}
                        surveyEmployee={tnp}
                      />
                    ))}
                    <PlaceholderNonParticipantBox
                      participant={participant}
                      position={Positions.Team}
                    />
                  </div>

                  {/* Reports */}
                  <div
                    style={{
                      display: "flex",
                      gap: 5,
                    }}
                  >
                    {reports.map((report) => (
                      <PreviewSurveyEmployeeBox
                        key={report._id}
                        surveyEmployee={report}
                      />
                    ))}
                    <PlaceholderNonParticipantBox
                      participant={participant}
                      position={Positions.Reports}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      <Drawer
        visible={drawerMode !== "hidden"}
        onClose={() => {
          setDrawerMode("hidden");
          form.resetFields();
        }}
        title={
          drawerMode === "add"
            ? `Adding a ${form.getFieldValue("position")}`
            : `Edit ${form.getFieldValue("position")}`
        }
        destroyOnClose={true}
        width={400}
        forceRender={true}
        keyboard={true}
      >
        <Form
          form={form}
          layout="vertical"
          labelCol={{
            style: {
              opacity: 0.8,
            },
          }}
          onFinish={async (v: SurveyEmployee) => {
            setIsSaveSurveyEmployeeLoading(true);

            const newSurveyEmployeeById = Object.assign({}, surveyEmployeeById);
            newSurveyEmployeeById[v._id] = {
              ...v,
              errorByField: {},
            };

            const req: ValidateUpdateSurveyCampaignEmployeesRequest = {
              surveyEmployees: Object.values(newSurveyEmployeeById),
            };
            try {
              await validateUpdateSurveyCampaignEmployees(
                req,
                surveyCampaignId
              );
              await saveAsDraftUpdateSurveyCampaignEmployees(
                req,
                surveyCampaignId
              );
              setSurveyEmployeeById(newSurveyEmployeeById);
              setDrawerMode("hidden");
              form.resetFields();
            } catch (err) {
              const validationErr =
                err as ValidateUpdateSurveyCampaignEmployeesErrorResponse;

              if (!validationErr.details) {
                message.error(validationErr.message);
                return;
              }

              const errorByFieldsForThisSurveyEmployee =
                validationErr.details[v._id];

              // treat as success if errors don't belong to this employee
              if (!errorByFieldsForThisSurveyEmployee) {
                await saveAsDraftUpdateSurveyCampaignEmployees(
                  req,
                  surveyCampaignId
                );
                setSurveyEmployeeById(newSurveyEmployeeById);
                setDrawerMode("hidden");
                form.resetFields();
              } else {
                const fields = [
                  "fname",
                  "lname",
                  "email",
                  "gender",
                  "team",
                ].map((name) => {
                  const error = errorByFieldsForThisSurveyEmployee[name];
                  return {
                    name: name,
                    errors: error ? [error] : [],
                  };
                });
                form.setFields(fields);
              }
            }

            setIsSaveSurveyEmployeeLoading(false);
          }}
        >
          {/* https://github.com/ant-design/ant-design/issues/16980#issuecomment-988808491 */}
          <Form.Item name="position" hidden>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="participantId" hidden>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="_id" hidden>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="createdAt" hidden>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item label="First Name" name="fname">
            <Input placeholder="John" />
          </Form.Item>
          <Form.Item label="Last Name" name="lname">
            <Input placeholder="Doe" />
          </Form.Item>
          <Form.Item label={"Email"} name="email">
            <Input placeholder="johndoe@email.com" />
          </Form.Item>
          <Form.Item label="Gender" name="gender">
            <Select showSearch placeholder={"Male"}>
              {[Gender.Male, Gender.Female].map((gender) => {
                return (
                  <Select.Option key={gender} value={gender}>
                    {gender}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Team" name="team">
            <Input placeholder="Logistics" />
          </Form.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              gap: 5,
            }}
          >
            <Button
              htmlType="submit"
              type="primary"
              loading={isSaveSurveyEmployeeLoading}
            >
              Save
            </Button>
          </div>
        </Form>
      </Drawer>

      <Affix offsetBottom={0}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 10,
            backgroundColor: "white",
            padding: 20,
          }}
        >
          <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
            <Button
              onClick={(e) => {
                const req: SaveAsDraftUpdateSurveyCampaignEmployeesRequest = {
                  surveyEmployees: Object.values(surveyEmployeeById),
                };

                setIsSaveAsDraftLoading(true);
                saveAsDraftUpdateSurveyCampaignEmployees(req, surveyCampaignId)
                  .then((res) => {
                    mutate(
                      `/surveyResponse/getBySurveyCampaignId/${surveyCampaignId}`
                    );

                    // hacky way to wait for mutate to be done
                    setTimeout(() => {
                      setIsSaveAsDraftLoading(false);
                    }, 1 * 1000);
                  })
                  .catch((err) => {
                    message.error(err.message);
                    setIsSaveAsDraftLoading(false);
                  });
              }}
              loading={isSaveAsDraftLoading}
              type={"default"}
            >
              {t(
                SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormSaveAsDraftBtn
              )}
            </Button>

            <Tooltip
              title={hasErrors ? "Unable to proceed. Errors found." : null}
            >
              <Button
                loading={isNextLoading}
                onClick={(e) => {
                  const surveyEmployees = Object.values(surveyEmployeeById);

                  const req: UpdateSurveyCampaignEmployeesRequest = {
                    surveyEmployees: surveyEmployees,
                  };

                  setIsNextLoading(true);
                  updateSurveyCampaignEmployees(req, surveyCampaignId)
                    .then(() => {
                      history.push(
                        `/surveycampaign/edit/preview?surveyCampaignId=${getQueryParam(
                          "surveyCampaignId"
                        )}`
                      );
                    })
                    .catch((err) => {
                      message.error(err.message);
                    })
                    .finally(() => {
                      setIsNextLoading(false);
                    });
                }}
                type="primary"
                // disable next if there are errors
                disabled={hasErrors}
              >
                <>
                  {t(
                    SurveyCampaign360FormKeys.surveyCampaign360Form_employeesNextBtn
                  )}
                </>
              </Button>
            </Tooltip>
          </div>
        </div>
      </Affix>
    </div>
  );
}
