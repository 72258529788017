import { PageHeader, Skeleton, Steps } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router";
import useSWR from "swr";
import {
  GetSurveyCampaignBySurveyCampaignIdResponse,
  GetSurveyTemplatesResponse,
} from "../../constants/apiRequestResponse";
import { SurveyTemplateType } from "../../constants/types";
import { fetcher } from "../../services/api";
import { SurveyCampaignFormKeys } from "../../services/i8tn/SurveyCampaignForm/keys";
import { getQueryParam } from "../../util/index";
import BasicInfo from "./BasicInfo";
import Preview from "./Preview";
import Upload360Employees from "./Upload360Employees";
import UploadEmployees from "./UploadEmployees";

export enum CreateSurveyCampaignStep {
  BasicInfo,
  UploadEmployees,
  Preview,
}

export default function SurveyCampaignFormPage() {
  const { t } = useTranslation();

  const surveyCampaignId = getQueryParam("surveyCampaignId") || "";

  const {
    data: getSurveyTemplatesRes,
    error: getSurveyTemplatesError,
    isLoading: isSurveyTemplatesLoading,
  } = useSWR<GetSurveyTemplatesResponse>(`/surveyTemplate/get`, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    errorRetryCount: 0,
  });

  const { data: getSurveyCampaignRes, error: getSurveyCampaignError } =
    useSWR<GetSurveyCampaignBySurveyCampaignIdResponse>(
      `/surveyCampaign/getBySurveyCampaignId/${surveyCampaignId}`,
      surveyCampaignId ? fetcher : null,
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        errorRetryCount: 0,
      }
    );

  useEffect(() => {
    window.onbeforeunload = function () {
      return "";
    };

    return () => {
      window.removeEventListener("beforeunload", () => {});
    };
  }, []);

  const surveyTemplate = getSurveyTemplatesRes?.surveyTemplates.find((st) => {
    return (
      st._id === getQueryParam("surveyTemplateId") ||
      st._id === getSurveyCampaignRes?.surveyCampaign?.surveyTemplateId
    );
  });

  if (getSurveyCampaignError) {
    switch (getSurveyCampaignError?.response?.status) {
      case 404:
        return (
          <div className="page-content">
            <p>Unable to find survey campaign.</p>
            <a href="/">Go back home.</a>
          </div>
        );
      default:
        return (
          <div className="page-content">
            <p>{getSurveyCampaignError?.response?.data}</p>
          </div>
        );
    }
  }

  function TitleCard() {
    if (isSurveyTemplatesLoading) {
      return <Skeleton active paragraph={{ rows: 1 }} />;
    }

    switch (surveyTemplate?.name) {
      case `Engagement`:
        return <PageHeader title={"Run an engagement survey"} />;
      case `Pulse`:
        return <PageHeader title={"Run a pulse survey"} />;
      case `360`:
        return <PageHeader title={"Run a 360 survey"} />;
      default:
        return <PageHeader title={"Run a survey"} />;
    }
  }

  return (
    <div className="page-content">
      <TitleCard />
      <div
        style={{
          display: "flex",
          gap: 10,
          flexDirection: "column",
          backgroundColor: "white",
          padding: 30,
          alignItems: "center",
        }}
      >
        {getSurveyCampaignError || getSurveyTemplatesError ? (
          <div>
            <p>Something went wrong</p>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              minHeight: "70vh",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 20,
              }}
            >
              <div
                style={{
                  width: "20%",
                  maxWidth: 300,
                }}
              >
                <Steps size="small" direction="vertical">
                  <Steps.Step
                    status={
                      window.location.pathname.includes("/employees")
                        ? "finish"
                        : window.location.pathname.includes("/preview")
                        ? "finish"
                        : "process"
                    }
                    title={t(
                      SurveyCampaignFormKeys.surveyCampaignForm_basicInfoString
                    )}
                  />
                  <Steps.Step
                    status={
                      window.location.pathname.includes("/employees")
                        ? "process"
                        : window.location.pathname.includes("/preview")
                        ? "finish"
                        : "wait"
                    }
                    title={t(
                      SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesString
                    )}
                  />
                  <Steps.Step
                    status={
                      window.location.pathname.includes("/preview")
                        ? "process"
                        : "wait"
                    }
                    title={t(
                      SurveyCampaignFormKeys.surveyCampaignForm_previewAndSendString
                    )}
                  />
                </Steps>
              </div>
              <div
                style={{
                  width: "80%",
                }}
              >
                <Switch>
                  <Route path="/surveycampaign/edit/employees">
                    {surveyTemplate?.type === SurveyTemplateType.feedback ? (
                      <Upload360Employees />
                    ) : surveyTemplate?.type === SurveyTemplateType.normal ? (
                      <UploadEmployees />
                    ) : (
                      <Skeleton />
                    )}
                  </Route>
                  <Route path="/surveycampaign/edit/preview">
                    <Preview />
                  </Route>
                  <Route
                    path={[
                      "/surveycampaign/create",
                      "/surveycampaign/create/basic",
                      "/surveycampaign/edit",
                      "/surveycampaign/edit/basic",
                    ]}
                  >
                    <BasicInfo />
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
