import { Breadcrumb, Button, Form, Input, message } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CreateAgreementCampaignRequest,
  GetUploadTncFilePresignedUrlRequest,
} from "../../../constants/apiRequestResponse";
import {
  createAgreementCampaign,
  getUploadTncFilePresignedUrl,
} from "../../../services/api";

const CreateAgreementCampaign: React.FC = () => {
  const [tncFileLink, setTncFileLink] = useState("");
  const history = useHistory();
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    try {
      const req: CreateAgreementCampaignRequest = {
        name: values.name,
        tncFileLink: tncFileLink,
      };

      const res = await createAgreementCampaign(req);
      if (!res.success) {
        throw new Error(res.message);
      }

      message.success("Agreement sent out successfully");

      history.goBack();
    } catch (err) {
      message.error(err);
    }
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>Agreements</Breadcrumb.Item>
        <Breadcrumb.Item>Create new agreement</Breadcrumb.Item>
      </Breadcrumb>
      <Form
        style={{ marginTop: 30 }}
        form={form}
        initialValues={{ name: "" }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="Title"
          name="name"
          rules={[{ required: true, message: "Please input the title!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Terms and condition file">
          <input
            type="file"
            name="pdf"
            accept="application/pdf"
            onChange={async (e) => {
              try {
                const file = e.target.files && e.target.files[0];

                if (!file) {
                  throw new Error("file not found");
                }
                const req: GetUploadTncFilePresignedUrlRequest = {
                  filename: file?.name,
                };

                const res = await getUploadTncFilePresignedUrl(req);
                if (!res.success) {
                  throw new Error(res.message);
                }
                if (!res.presignedURL) {
                  throw new Error("missing presigned url");
                }

                const uploadRes = await fetch(res.presignedURL, {
                  method: "PUT",
                  body: file,
                });

                if (uploadRes.ok) {
                  setTncFileLink(res.presignedURL);
                  message.success(`File uploaded`);
                } else {
                  throw new Error(uploadRes.statusText);
                }
              } catch (err) {
                message.error(err?.toString());
              }
            }}
          />
        </Form.Item>
        <div
          style={{
            marginTop: 100,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: 10,
          }}
        >
          <Button onClick={() => history.goBack()}>Back</Button>
          <div
            style={{
              display: "flex",
              gap: 10,
            }}
          >
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Send to all
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CreateAgreementCampaign;
