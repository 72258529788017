import { Button, Form, Input, message, Spin, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import {
  CreateSurveyCampaignRequest,
  GetSurveyCampaignBySurveyCampaignIdResponse,
  UpdateSurveyCampaignBasicRequest,
} from "../../constants/apiRequestResponse";
import TEST_IDS from "../../constants/cypressTestIds";
import { SurveyCampaign } from "../../constants/types";
import {
  createSurveyCampaign,
  fetcher,
  updateSurveyCampaignBasic,
} from "../../services/api";
import { SurveyCampaignFormKeys } from "../../services/i8tn/SurveyCampaignForm/keys";
import { getQueryParam, parseDate } from "../../util";

export default function BasicInfo() {
  const isEditMode = window.location.pathname.includes("edit");

  const { t } = useTranslation();
  const history = useHistory();
  const [isNextButtonLoading, setIsNextButtonLoading] = useState(false);
  const [form] = Form.useForm();

  const { data: getSurveyCampaignRes, isLoading: isSurveyCampaignLoading } =
    useSWR<GetSurveyCampaignBySurveyCampaignIdResponse>(
      isEditMode
        ? `/surveyCampaign/getBySurveyCampaignId/${getQueryParam(
            "surveyCampaignId"
          )}`
        : null,
      fetcher,
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        errorRetryCount: 0,
      }
    );

  if (getSurveyCampaignRes?.surveyCampaign) {
    form.setFieldsValue(getSurveyCampaignRes?.surveyCampaign);
  }

  return (
    <div>
      <div>
        <Typography.Title level={3}>
          {t(SurveyCampaignFormKeys.surveyCampaignForm_basicInfoString)}
        </Typography.Title>
      </div>
      <Form
        form={form}
        layout="vertical"
        onFinish={async () => {
          setIsNextButtonLoading(true);

          try {
            const formValues = form.getFieldsValue(true) as SurveyCampaign;
            if (isEditMode) {
              const surveyCampaignId = getQueryParam("surveyCampaignId") || "";
              const req: UpdateSurveyCampaignBasicRequest = {
                name: formValues.name,
              };

              await updateSurveyCampaignBasic(req, surveyCampaignId);

              history.push(
                `/surveycampaign/edit/employees?surveyCampaignId=${surveyCampaignId}`
              );
            } else {
              const req: CreateSurveyCampaignRequest = {
                surveyCampaign: {
                  name: formValues.name,
                  surveyTemplateId: getQueryParam("surveyTemplateId") || "",
                },
              };
              const res = await createSurveyCampaign(req);
              if (!res.success) throw new Error(res.message);
              history.push(
                `/surveycampaign/edit/employees?surveyCampaignId=${res.surveyCampaign._id}`
              );
            }
          } catch (err) {
            switch (typeof err) {
              case "object":
                message.error(err?.toString());
                break;
              default:
                message.error(err);
            }
            setIsNextButtonLoading(false);
          }
        }}
      >
        <Form.Item
          data-testid={TEST_IDS.SURVEY_CAMPAIGN_FORM.campaign_name_label_id}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          {isSurveyCampaignLoading ? (
            <Spin />
          ) : (
            <Input placeholder={`e.g. My Survey ${parseDate(moment.now())}`} />
          )}
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 30,
            }}
          >
            <Button
              htmlType="submit"
              type="primary"
              loading={isNextButtonLoading}
            >
              {isEditMode ? (
                <>{t(SurveyCampaignFormKeys.surveyCampaignForm_nextBtn)}</>
              ) : (
                <>{t(SurveyCampaignFormKeys.surveyCampaignForm_createBtn)}</>
              )}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}
