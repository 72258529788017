import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Select,
  Table,
  Typography,
} from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import shortId from "shortid";
import useSWR from "swr";
import { read } from "xlsx";
import { SessionContext } from "../../App";
import {
  GetSurveyResponseBySurveyCampaignIdResponse,
  UpdateSurveyCampaignEmployeesRequest,
  ValidateUpdateSurveyCampaignEmployeesErrorResponse,
  ValidateUpdateSurveyCampaignEmployeesRequest,
} from "../../constants/apiRequestResponse";
import { Gender, Positions, SurveyEmployee } from "../../constants/types";
import {
  fetcher,
  updateSurveyCampaignEmployees,
  validateUpdateSurveyCampaignEmployees,
} from "../../services/api";
import { SurveyCampaignFormKeys } from "../../services/i8tn/SurveyCampaignForm/keys";
import { getQueryParam } from "../../util";

/**
 * 30/5/2022 daniel.kwok
 * Custom type, closely resembling SurveyEmployee, but with a couple extra properties
 */
type Row = {
  _id: string;
  fname: string;
  lname: string;
  email: string;
  gender: Gender;
  position: Positions | string;
  team: string;
  errors: string[];
};

export default function UploadEmployees() {
  const context = useContext(SessionContext);
  const history = useHistory();

  const surveyCampaignId = getQueryParam("surveyCampaignId") || "";

  const { data: getSurveyResponseRes, isLoading: isSurveyResponseLoading } =
    useSWR<GetSurveyResponseBySurveyCampaignIdResponse>(
      `/surveyResponse/getBySurveyCampaignId/${surveyCampaignId}`,
      fetcher,
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        errorRetryCount: 0,
      }
    );

  const [rows, setRows] = useState<Row[]>([]);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [showAddEmployeeDrawer, setShowAddEmployeeeDrawer] = useState(false);
  const [showEditEmployeeDrawer, setShowEditEmployeeeDrawer] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [isAddLoading, setIsAddLoading] = useState(false);
  const [editForm] = Form.useForm();
  const [addForm] = Form.useForm();
  const { t } = useTranslation();

  function getErrorCount(rows: Row[]): number {
    return rows.filter((row) => row.errors.length > 0).length;
  }

  const errorCount = getErrorCount(rows);

  const validateRows = useCallback(
    async (rows: Row[]): Promise<Row[]> => {
      const req: ValidateUpdateSurveyCampaignEmployeesRequest = {
        surveyEmployees: rows,
      };

      await validateUpdateSurveyCampaignEmployees(req, surveyCampaignId).catch(
        (err: ValidateUpdateSurveyCampaignEmployeesErrorResponse) => {
          // map row.error depending on errors returned by API
          rows = rows.map((row) => {
            const errorByField = err.details[row._id];
            if (!errorByField) {
              row.errors = [];
              return row;
            }

            row.errors = Object.values(errorByField);
            return row;
          });
        }
      );
      return rows;
    },
    [surveyCampaignId]
  );

  useEffect(() => {
    if (getSurveyResponseRes?.surveyResponses?.length === 0) return;

    const rows =
      getSurveyResponseRes?.surveyResponses.map((sr) => {
        return {
          ...sr.surveyEmployee,
          errors: [],
        };
      }) || [];
    // first prefill the table
    setRows(rows);

    // and then update the table with validated results
    validateRows(rows).then((validatedRows) => setRows(validatedRows));
  }, [getSurveyResponseRes, validateRows]);

  const exportCsv = (rows: Row[], name: string) => {
    const csvRows = [
      ["First Name", "Last Name", "Position", "Email", "Gender", "Team"],
      ...rows.map((r) => {
        return [r.fname, r.lname, r.position, r.email, r.gender, r.team];
      }),
    ];

    let csvContent = csvRows.map((rowArray) => rowArray.join(",")).join("\r\n");

    // Create a Blob
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `${name ? name : `employees.csv`}`);
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <div>
        {errorCount > 0 ? (
          <Alert
            showIcon
            message={`${errorCount} ${t(
              SurveyCampaignFormKeys.surveyCampaignForm_errorsFoundString
            )}`}
            type="error"
          />
        ) : null}
        <Typography.Title level={3}>
          {t(
            SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesTitleString
          )}
        </Typography.Title>
        <p>
          {t(
            SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesDownloadString
          )}
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => {
              const sampleRows: Row[] = [
                {
                  _id: "",
                  fname: `John`,
                  lname: `Doe`,
                  email: `johndoe@${context?.company?.emailDomains[0]}`,
                  gender: Gender.Male,
                  team: `Finance`,
                  position: `Manager`,
                  errors: [],
                },
                {
                  _id: "",
                  fname: `Jane`,
                  lname: `Doe`,
                  email: `johndoe@${context?.company?.emailDomains[0]}`,
                  gender: Gender.Female,
                  team: `Tech`,
                  position: `Engineer`,
                  errors: [],
                },
              ];
              exportCsv(sampleRows, "sample.csv");
            }}
          >
            {t(
              SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesSampleCSVLink
            )}
          </Button>
          {t(
            SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesExampleString
          )}
        </p>
        <input
          type="file"
          onChange={(e) => {
            /**
             * 1/6/2022 daniel.kwok
             * It's possible for user to manually create employees, but then decide to upload.
             * The correct behavior would be to override it.
             * However, warn user before proceeding first.
             */
            if (rows.length > 0) {
              Modal.confirm({
                title: (
                  <>
                    {t(
                      SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesOverridePrompt
                    )}
                  </>
                ),
                onOk: () => {
                  parseFile(e);
                },
              });
            } else {
              parseFile(e);
            }

            function parseFile(e: React.ChangeEvent<HTMLInputElement>) {
              const HEADERS = [
                "First Name",
                "Last Name",
                "Position",
                "Email",
                "Gender",
                "Team",
              ];
              const file = e.target.files && e.target.files[0];

              if (file) {
                const reader = new FileReader();
                const rABS = !!reader?.readAsBinaryString;
                reader.onload = (e) => {
                  try {
                    setRows([]);
                    const bstr = e?.target?.result;
                    const wb = read(bstr, { type: rABS ? "binary" : "array" });

                    const sheet = Object.values(wb.Sheets)[0];

                    const headers = ["A1", "B1", "C1", "D1", "E1", "F1"].map(
                      (char) => {
                        const col = sheet[char];
                        return col?.v;
                      }
                    );

                    if (JSON.stringify(headers) !== JSON.stringify(HEADERS)) {
                      throw new Error(`Wrong template`);
                    }

                    const STARTING_ROW = 2;
                    const ENDING_ROW = Math.max(
                      ...Object.keys(sheet)
                        .map((key) => Number(key.replace(/^\D+/g, "")))
                        .filter((n) => n)
                    );

                    let _rows: Row[] = [];
                    for (let row = STARTING_ROW; row <= ENDING_ROW; row++) {
                      /**
                       * 1/6/2022 daniel.kwok
                       *
                       * Possible for a cell to be empty.
                       * Hence need fallback value of empty string, ''
                       */
                      const r: Row = {
                        _id: shortId(),
                        fname: sheet[`A${row}`]?.v || "",
                        lname: sheet[`B${row}`]?.v || "",
                        position: sheet[`C${row}`]?.v || "",
                        email: sheet[`D${row}`]?.v || "",
                        gender: sheet[`E${row}`]?.v || "",
                        team: sheet[`F${row}`]?.v || "",
                        errors: [],
                      };
                      _rows.push(r);
                    }

                    setRows(_rows);
                    validateRows(_rows).then((res) => {
                      setRows(res);
                    });
                  } catch (err) {
                    console.log(err);
                    message.error(
                      typeof err === "string"
                        ? err
                        : typeof err === "object"
                        ? err?.toString()
                        : `Something went wrong`
                    );
                  }
                };

                if (rABS) reader.readAsBinaryString(file);
                else reader.readAsArrayBuffer(file);
              }
            }
          }}
        />
        <p style={{ color: "grey" }}>
          {t(
            SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesSupportedFormatsString
          )}
        </p>
      </div>

      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 10,
            marginBottom: 10,
            gap: 10,
          }}
        >
          <Button
            onClick={() => {
              exportCsv(rows, "employees.csv");
            }}
            disabled={rows.length === 0}
          >
            Export as csv
          </Button>
          <Button
            onClick={() => {
              setShowAddEmployeeeDrawer(true);
            }}
          >
            {t(SurveyCampaignFormKeys.surveyCampaignForm_addAnEmployeeBtn)}
          </Button>
        </div>
        <Table
          loading={!getSurveyResponseRes && isSurveyResponseLoading}
          rowKey="_id"
          columns={[
            {
              title: "",
              dataIndex: "errors",
              render: (errors: string[]) => {
                return errors.length > 0 ? (
                  <CloseCircleOutlined style={{ color: "red" }} />
                ) : (
                  <CheckCircleOutlined style={{ color: "green" }} />
                );
              },
            },
            {
              title: (
                <>
                  {t(
                    SurveyCampaignFormKeys.surveyCampaignForm_employeesFirstNameTableHeader
                  )}
                </>
              ),
              dataIndex: "fname",
            },
            {
              title: (
                <>
                  {t(
                    SurveyCampaignFormKeys.surveyCampaignForm_employeesLastNameTableHeader
                  )}
                </>
              ),
              dataIndex: "lname",
            },
            {
              title: (
                <>
                  {t(
                    SurveyCampaignFormKeys.surveyCampaignForm_employeesEmailTableHeader
                  )}
                </>
              ),
              dataIndex: "email",
            },
            {
              title: (
                <>
                  {t(
                    SurveyCampaignFormKeys.surveyCampaignForm_employeesGenderTableHeader
                  )}
                </>
              ),
              dataIndex: "gender",
            },
            {
              title: (
                <>
                  {t(
                    SurveyCampaignFormKeys.surveyCampaignForm_employeesTeamTableHeader
                  )}
                </>
              ),
              dataIndex: "team",
            },
            {
              title: (
                <>
                  {t(
                    SurveyCampaignFormKeys.surveyCampaignForm_employeesPositionTableHeader
                  )}
                </>
              ),
              dataIndex: "position",
            },
            {
              title: (
                <>
                  {t(
                    SurveyCampaignFormKeys.surveyCampaignForm_employeesErrorsTableHeader
                  )}
                </>
              ),
              dataIndex: "errors",
              render: (errors: string[]) => {
                return (
                  <span style={{ color: "red", marginRight: 5 }}>
                    {errors.map((e, i) => (
                      <li key={i}>{e}</li>
                    ))}
                  </span>
                );
              },
            },
            {
              title: (
                <>
                  {t(
                    SurveyCampaignFormKeys.surveyCampaignForm_employeesActionTableHeader
                  )}
                </>
              ),
              render: (rowData: Row) => {
                return (
                  <div style={{ display: "flex" }}>
                    <Button
                      type="link"
                      onClick={() => {
                        editForm.setFields([
                          {
                            name: "_id",
                            value: rowData._id,
                          },
                          {
                            name: "errors",
                            value: rowData.errors,
                          },
                          {
                            name: "fname",
                            value: rowData.fname,
                          },
                          {
                            name: "lname",
                            value: rowData.lname,
                          },
                          {
                            name: "email",
                            value: rowData.email,
                          },
                          {
                            name: "gender",
                            value: rowData.gender,
                          },
                          {
                            name: "position",
                            value: rowData.position,
                          },
                          {
                            name: "team",
                            value: rowData.team,
                          },
                        ]);
                        setShowEditEmployeeeDrawer(true);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      type="link"
                      danger
                      onClick={() => {
                        const _rows = rows.filter((r) => r._id !== rowData._id);
                        setRows(_rows);
                      }}
                    >
                      {t(
                        SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormDeleteBtn
                      )}
                    </Button>
                  </div>
                );
              },
            },
          ]}
          style={{
            width: "100%",
          }}
          dataSource={rows}
          scroll={{
            x: "max-content",
          }}
          pagination={false}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 30,
        }}
      >
        <Button onClick={() => history.goBack()} type="link">
          <>{t(SurveyCampaignFormKeys.surveyCampaignForm_backBtn)}</>
        </Button>
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          <Button
            loading={isNextLoading}
            onClick={(e) => {
              const surveyEmployees = rows.map((row) => {
                const se: SurveyEmployee = {
                  _id: row._id,
                  fname: row.fname,
                  lname: row.lname,
                  email: row.email,
                  gender: row.gender,
                  position: row.position,
                  team: row.team,
                  createdAt: Date.now(),
                };

                return se;
              });

              const req: UpdateSurveyCampaignEmployeesRequest = {
                surveyEmployees: surveyEmployees,
              };

              setIsNextLoading(true);
              updateSurveyCampaignEmployees(req, surveyCampaignId)
                .then(() => {
                  history.push(
                    `/surveycampaign/edit/preview?surveyCampaignId=${getQueryParam(
                      "surveyCampaignId"
                    )}`
                  );
                })
                .catch((err) => {
                  message.error(err.message);
                })
                .finally(() => {
                  setIsNextLoading(false);
                });
            }}
            type="primary"
            disabled={errorCount > 0 || rows.length === 0}
          >
            {t(SurveyCampaignFormKeys.surveyCampaignForm_nextBtn)}
          </Button>
        </div>
      </div>

      <Drawer
        title={t(
          SurveyCampaignFormKeys.surveyCampaignForm_employeesAddNewEmployeeString
        )}
        visible={showAddEmployeeDrawer}
        onClose={() => {
          setShowAddEmployeeeDrawer(false);
        }}
        destroyOnClose={true}
        width={400}
      >
        <Form
          form={addForm}
          layout="vertical"
          onFinish={async (v: Row) => {
            v._id = shortId();
            v.errors = [];

            const _rows = [v, ...rows];
            setIsAddLoading(true);
            validateRows(_rows)
              .then((validatedRows) => {
                setRows(validatedRows);

                addForm?.resetFields();
                setShowAddEmployeeeDrawer(false);
              })
              .finally(() => setIsAddLoading(false));
          }}
        >
          <Form.Item name="participantId" hidden />
          <Form.Item name="errors" hidden />
          <Form.Item
            label={t(
              SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormFirstNameString
            )}
            name="fname"
            rules={[{ required: true, message: `Required field` }]}
          >
            <Input placeholder="John" />
          </Form.Item>
          <Form.Item
            label={t(
              SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormLastNameString
            )}
            name="lname"
            rules={[{ required: true, message: `Required field` }]}
          >
            <Input placeholder="Doe" />
          </Form.Item>
          <Form.Item
            label={t(
              SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormEmailString
            )}
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                validator: (_, value) => {
                  for (let emailDomain of context?.company?.emailDomains ||
                    []) {
                    const regex = new RegExp(`.+@${emailDomain}$`);
                    if (value.match(regex)) {
                      return Promise.resolve();
                    }
                  }

                  return Promise.reject();
                },
                message: `${t(
                  SurveyCampaignFormKeys.surveyCampaignForm_emailMustEndString
                )} ${context?.company?.emailDomains.toString()} ${t(
                  SurveyCampaignFormKeys.surveyCampaignForm_egJohnDoeString
                )}${context?.company?.emailDomains[0]}`,
              },
            ]}
          >
            <Input placeholder="johndoe@email.com" />
          </Form.Item>
          <Form.Item
            label={t(
              SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormGenderString
            )}
            name="gender"
            rules={[
              {
                required: true,
                type: "enum",
                enum: [Gender.Male, Gender.Female],
                message: `Required field`,
              },
            ]}
          >
            <Select showSearch placeholder={"Male"}>
              {
                /** TODO daniel.kwok
                 * convert to i18tn compatible?
                 * -sherlyn
                 */
                ["Male", "Female"].map((gender) => {
                  return (
                    <Select.Option key={gender} value={gender}>
                      {gender}
                    </Select.Option>
                  );
                })
              }
            </Select>
          </Form.Item>
          <Form.Item
            label={t(
              SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormPositionString
            )}
            name="position"
          >
            <Input placeholder="Manager" />
          </Form.Item>
          <Form.Item
            label={t(
              SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormTeamString
            )}
            name="team"
          >
            <Input placeholder="Finance" />
          </Form.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              gap: 5,
            }}
          >
            <Button htmlType="submit" type="primary" loading={isAddLoading}>
              <>
                {t(
                  SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormSaveBtn
                )}
              </>
            </Button>
          </div>
        </Form>
      </Drawer>

      <Drawer
        visible={showEditEmployeeDrawer}
        onClose={() => {
          setShowEditEmployeeeDrawer(false);
        }}
        destroyOnClose={true}
        width={400}
      >
        <Form
          form={editForm}
          layout="vertical"
          onFinish={async (v: Row) => {
            const index = rows.findIndex((r) => r._id === v._id);
            const _rows = JSON.parse(JSON.stringify(rows));
            _rows[index] = v;

            setIsEditLoading(true);
            validateRows(_rows)
              .then((validatedRows) => {
                setRows(validatedRows);
                setShowEditEmployeeeDrawer(false);
                editForm?.resetFields();
              })
              .finally(() => setIsEditLoading(false));
          }}
        >
          <Form.Item name="participantId" hidden />
          <Form.Item name="errors" hidden />
          <Form.Item name="_id" hidden />
          <Form.Item
            label={t(
              SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormFirstNameString
            )}
            name="fname"
            rules={[{ required: true, message: `Required field` }]}
          >
            <Input placeholder="John" />
          </Form.Item>
          <Form.Item
            label={t(
              SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormLastNameString
            )}
            name="lname"
            rules={[{ required: true, message: `Required field` }]}
          >
            <Input placeholder="Doe" />
          </Form.Item>
          <Form.Item
            label={t(
              SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormEmailString
            )}
            name="email"
            rules={[
              /**
               * 1/6/2022 daniel.kwok
               * Not sure if company emailDomain validation is required here.
               * Might need to cross check
               */
              {
                required: true,
                type: "email",
                validator: (_, value) => {
                  for (let emailDomain of context?.company?.emailDomains ||
                    []) {
                    const regex = new RegExp(`.+@${emailDomain}$`);
                    if (value.match(regex)) {
                      return Promise.resolve();
                    }
                  }

                  return Promise.reject();
                },
                message: `Email must end with one of ${context?.company?.emailDomains.toString()}. E.g. johndoe@${
                  context?.company?.emailDomains[0]
                }`,
              },
            ]}
          >
            <Input placeholder="johndoe@email.com" />
          </Form.Item>
          <Form.Item
            label={t(
              SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormGenderString
            )}
            name="gender"
            rules={[
              {
                required: true,
                type: "enum",
                enum: [Gender.Male, Gender.Female],
                message: `Required field`,
              },
            ]}
          >
            <Select showSearch placeholder={"Male"} id="gender">
              {
                /** TODO daniel.kwok
                 * convert to i18tn compatible?
                 * -sherlyn
                 */
                ["Male", "Female"].map((gender) => {
                  return (
                    <Select.Option key={gender} value={gender}>
                      {gender}
                    </Select.Option>
                  );
                })
              }
            </Select>
          </Form.Item>
          <Form.Item
            label={t(
              SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormPositionString
            )}
            name="position"
          >
            <Input placeholder="Manager" />
          </Form.Item>
          <Form.Item
            label={t(
              SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormTeamString
            )}
            name="team"
          >
            <Input placeholder="Finance" />
          </Form.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              gap: 5,
            }}
          >
            <Button loading={isEditLoading} htmlType="submit" type="primary">
              <>
                {t(
                  SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormSaveBtn
                )}
              </>
            </Button>
          </div>
        </Form>
      </Drawer>
    </div>
  );
}
