import { Tag } from "antd";
import React from "react";
import { POSITION_COLORS } from "../constants/misc";
import { Positions } from "../constants/types";

interface Prop {
  position?: Positions | string;
  team?: string;
}

export default function PositionsTag(props: Prop) {
  switch (props.position) {
    case Positions.LineManager:
      return (
        <Tag color={POSITION_COLORS["Line Manager"]}>
          {Positions.LineManager}
        </Tag>
      );
    case Positions.Participant:
      return (
        <Tag color={POSITION_COLORS.Participant}>{Positions.Participant}</Tag>
      );
    case Positions.Reports:
      return <Tag color={POSITION_COLORS.Reports}>{Positions.Reports}</Tag>;
    case Positions.Team:
      return <Tag color={POSITION_COLORS.Team}>{Positions.Team}</Tag>;
    default:
      return <Tag>{props.position}</Tag>;
  }
}
